import { FabProps } from "@mui/material";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setCookie, getCookie, deleteCookie } from "cookies-next";
import Router from "next/router";

export const setLang = createAsyncThunk(
  "lang/set",
  async (keyword?: string): Promise<any> => {
    return keyword;
  }
);
