import type { AppProps } from "next/app";
import { wrapper } from "@/store/store";
import React from "react";
import { appDispatch, appSelector } from "@/store/hooks";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// add bootstrap css
// import "bootstrap/dist/css/bootstrap.css";
import "@/public/static/css/stylesheet.css";

//slide
import "../public/static/css/embla.css";

function MyApp({ Component, ...rest }: AppProps) {
  const { store, props } = wrapper.useWrappedStore(rest);
  const { pageProps } = props;
  return (
    <Provider store={store}>
      <PersistGate persistor={store.persistor} loading={null}>
        <Component {...pageProps} />
      </PersistGate>
    </Provider>
  );
}

export default wrapper.withRedux(MyApp);
