import { createReducer } from "@reduxjs/toolkit";
import { setLang } from "@/features/admin/lang/actions";

interface Lang {
  lang: String;
}
const initialState: Lang = {
  lang: "th",
};

export const langReducer = createReducer(initialState, (builder) => {
  builder.addCase(setLang.fulfilled, (state, action) => {
    state.lang = action.payload;
  });
});

export default langReducer;
